import LOCALES from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'home.banner.heading': 'Providing peace of{br}mind for your device',
        'home.banner.btnText': 'Choose your phone protection',
        'home.protection.heading': 'Concerned you might drop your device or that it might get stolen?',
        'home.protection.instantCover.heading': 'Instant cover',
        'home.protection.instantCover.paragraph': 'You’re covered from the moment you buy and you can make up to 2 claims during 12 months of your cover.',
        'home.protection.worldWideCover.heading': 'World wide cover',
        'home.protection.worldWideCover.paragraph': 'You’ll be pleased to know that our insurance includes worldwide cover, so you can be rest assured to take your device with you wherever you go.',
        'home.protection.deviceMakeModel.heading': 'Any make, any model up to 90 days old',
        'home.protection.deviceMakeModel.paragraph': 'We insure any phone bought new from an Austria Drei store within 90 days of purchase.',
        'home.protection.deviceTrust.heading': 'Quality cover from a company you can trust',
        'home.protection.deviceTrust.paragraph': 'In collaboration with leading insurer AIG.',
        'home.products.popular' : "Most Comprehensive",
        "home.products.cta.learnmore":"Learn More",
        'home.products.yourDevice': 'Your Device',
        'home.products.heading': 'Choose your protection',
        'home.footer.tnc': "Terms and Conditions",
        'home.footer.disclaimer': "Disclaimer",
        'home.footer.privacyNcookie': "Privacy and Cookie Policy",
        'home.footer.copyrights': "© Bolttech Digital Brokerage (EU) SARL.",
        'home.footer.allRightsReserved': "All rights reserved.",
        'home.footer.thePower': "The power of <bold>connection</bold>.",
        'ordersummary.insuredDevice': 'Insured device',
        'ordersummary.insuredDevice.brand': 'Brand',
        'ordersummary.insuredDevice.model': 'Model',
        'ordersummary.insuredDevice.dateOfPurchase': 'Date of purchase',
        'ordersummary.insuredDevice.mobileNumber': 'Mobile number',
        'ordersummary.insuredDevice.imei': 'IMEI',
        'ordersummary.policyInfo': 'Policy information',
        'ordersummary.policyInfo.name': 'Name',
        'ordersummary.policyInfo.surname': 'Surname',
        'ordersummary.policyInfo.companyName': 'Company name',
        'ordersummary.policyInfo.email': 'Email',
        'ordersummary.policyInfo.sContactNo': 'Secondary contact number',
        'ordersummary.policyInfo.email': 'Email',
        'ordersummary.policyInfo.street': 'Street',
        'ordersummary.policyInfo.postalCode': 'Postal code',
        'ordersummary.policyInfo.city': 'City',
        'ordersummary.paymentInfo': 'Payment information',
        'ordersummary.paymentInfo.method': 'Method',
        'ordersummary.paymentInfo.methodValue': 'Drei billing',
        'ordersummary.paymentInfo.ocurrence': 'Ocurrence',
        'ordersummary.paymentInfo.ocurrenceValue': 'Monthly during your current cycle',
        'ordersummary.btn.submit': 'Submit',
        'deviceinfo.heading': "Insured Device",
        'form.cta.continue': "Continue",
        "form.optional": "Optional",
        "progress.item1": "Device Information",
        "progress.item2": "Policy Holder",
        "progress.item3": " Summary",
        "deviceinfo.form.brand": 'Brand',
        "deviceinfo.form.model": 'Model',
        "deviceinfo.form.dateofPurchase": 'Date of Purchase',
        "deviceinfo.form.customerPhoneNo": 'Mobile Number',
        "deviceinfo.form.imei": "IMEI",
        "plan.limit.header": "Max. limit per claim",
        "plan.collabration.header": "In collabration with",
        "plan.contract.header": "Contract period",
        "plan.payment.header": "Payment",
        "plan.total.header": "Total",
        "contactinfo.form1.header": "Contact Information",
        "contactinfo.form2.header": "Address",
        "contact.form.customerFirstName": "Name",
        "contact.form.customerLastName": "Surname",
        "contact.form.companyName": "Company",
        "contact.form.customerEmail": "Email",
        "contact.form.customerPhoneNo": "Mobile Number",
        "contact.form.customerSecondaryPhoneNo": "Secondary contact number",
        "contact.form.street1": "Street 1",
        "contact.form.street2": "Street 2",
        "contact.form.zipCode": "Postal Code",
        "contact.form.city": "City",
        "contact.form.customerFirstName.errorText": "Please enter your name",
        "contact.form.customerLastName.errorText": "Please enter your surname",
        "contact.form.customerEmail.errorText": "Please enter a valid email",
        "contact.form.street1.errorText": "Please enter your address",
        "contact.form.zipCode.errorText": "Please a valid 4 digit postal code",
        "contact.form.city.errorText": "Please enter your city",
        "contact.form.userConsent.errorText": "You need to read and accept this consent",
        "contact.form.customerSecondaryPhoneNo.errorText": "Please enter a valid phone number",
        "contact.form.userConsentText": "I confirm that the AVBs have been made available to me and concent the processing of my data by bolttech, Inc. in the EU for the fulfillment of the contract. Please see our Privacy Policy for more details on how we will use your personal details.",
        "payment.success.message1":"Your device is covered.",
        "payment.success.message2":"Look out for our welcome email!",
        "payment.failed.message1":"Unfortunately, your request could not be processed at this time.",
        'deviceinfo.verify.imei.error' : "The device cannot be enrolled in Apple Care Service. Contact Apple Care for more information"
    }
}

export const PRODUCT_DESCRIPTION = {
   // 'AT_DREI_SMART_COVER_PLUS_1M': 'This product is suitable for someone who needs a damage and theft cover for their mobile device. This includes cover for a mechanical or hardware breakdown, accidental damage such as a smashed screen, and theft. This cover also gives you the option to choose between a replacement for your damaged device or a repair option  Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   // 'AT_DREI_SMART_COVER_1M': 'This product is suitable for someone who needs a breakdown and accidental damage cover for their mobile device. It excludes cover for theft i.e. in case your device is stolen. Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   // 'AT_DREI_ADLD_EW_1M': 'This product is suitable for someone who needs a policy cover just the display for their mobile device.  Protect just the screen of your device in case of accidental damage or internal breakdown.  This cover excludes all mechanical breakdown such as broken keypad, and malfunction hardware.  Furthermore, it excludes cover for theft i.e. in case your device is stolen.  Please see terms and conditions of your cover for full details including deductibles and exclusions that apply'
   'ATDREIAUSADLDEWTHEFT12': 'This product is suitable for someone who needs a damage and theft cover for their mobile device. This includes cover for a mechanical or hardware breakdown, accidental damage such as a smashed screen, and theft. This cover also gives you the option to choose between a replacement for your damaged device or a repair option  Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   'ATDREIAUSADLDEW12': 'This product is suitable for someone who needs a breakdown and accidental damage cover for their mobile device. It excludes cover for theft i.e. in case your device is stolen. Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   'ATDREIAUSADLDEWTHEFT1201': 'This product is suitable for someone who needs a damage and theft cover for their mobile device. This includes cover for a mechanical or hardware breakdown, accidental damage such as a smashed screen, and theft. This cover also gives you the option to choose between a replacement for your damaged device or a repair option  Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   'ATDREIAUSADLDEW1201': 'This product is suitable for someone who needs a breakdown and accidental damage cover for their mobile device. It excludes cover for theft i.e. in case your device is stolen. Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   'ATDREIAUSADLDEWTHEFT101': 'This product is suitable for someone who needs a damage and theft cover for their mobile device. This includes cover for a mechanical or hardware breakdown, accidental damage such as a smashed screen, and theft. This cover also gives you the option to choose between a replacement for your damaged device or a repair option  Please see terms and conditions of your cover for full details including deductibles and exclusions that apply',
   'ATDREIAUSADLDEW101': 'This product is suitable for someone who needs a breakdown and accidental damage cover for their mobile device. It excludes cover for theft i.e. in case your device is stolen. Please see terms and conditions of your cover for full details including deductibles and exclusions that apply'
   
}

export const FEATURE = {
    EXTEND_PROTECTION: 'Extended protection in case of any accidental damage to either the screen or any other device part and internal breakdown',
    CHOOSE_BETWEEN: 'Choose between repair or replacement of your device',
    REPLACEMENT: 'Replacement of your device in case of theft or robbery',
    PROTECT: 'Protect just the screen of your device in case of accidental damage or internal breakdown',
    FREE_PROTECTION_FIRST_2_MONTHS: 'Two months free with monthly prolongation upon 12 months fixed coverage',
    FREE_PROTECTION_FIRST_MONTHS: 'First month free with monthly prolongation',
    DISCOUNTED_PREMIUM_FF: '-50% on the regular premium with monthly insurance renewal and no commitment',
    THEFT_PROTECTION_FF: 'Theft protection is also included',
    ACS_FREE_PROTECTION_FIRST_2_MONTHS: 'Two months free with monthly renewal after 24 months of fixed coverage',
    ACS_EXTEND_PROTECTION: 'Protect your device in the event of accidental damage, as well as electronic and mechanical defects after the manufacturer\'s warranty has expired',
    ACS_CHOOSE_BETWEEN: 'Choose between repairing and replacing your device',
    ACS_REPLACEMENT: 'Replacing your device in case of robbery or theft'
}

export const BREAKDOWN = {
    HEADING: 'Breakdown',
    WE_WILL_COVER: 'We will cover you if your device breaks down outside of the manufacturers guarantee period. For example,  broken buttons or headphone jack or other mechanical breakdowns.',
    WE_WILL_COVER_TERMS: 'We will cover you if your device display breaks down outside of the manufacturers guarantee period.  Terms apply',
    PLEASE_SEE: 'Please see terms and conditions of your cover for full details including deductibles and exclusions that apply'
}

export const ACCIDENTAL_DAMAGE = {
    HEADING: 'Accidental Damage',
    PARA_1: 'If your device is damaged as the result of an accident such as screen damage due to accidental fall, or your device accidentally comes into contact with liquid, then we will repair or replace it.',
    PARA_2: 'Please see terms and conditions of your cover for full details including deductibles and exclusions that apply'
}

export const REPLACE_REPAIR = {
    HEADING: 'Replace/Repair',
    PARA_1: 'In case your device is damaged, you have the option to choose between a replacement or repairs (where possible).  Terms apply.',
}

export const THEFT = {
    HEADING: 'Theft',
    PARA_1: 'If you are unlucky enough to have your device stolen we will replace it.  Terms apply.',
}

export const REPAIR = {
    HEADING: 'Repair',
    PARA_1: 'Have you display repaired in a way that suits you - walk in and mail in options available  Terms apply',
}

export const PRODUCT_NAME = {
    'ATDREIAUSADLDEWTHEFT12': 'Mobile<br/>Cover Plus',
    'ATDREIAUSADLDEW12': 'Mobile<br/>Cover',
    'ATDREIAUSADLDEWTHEFT1201': 'Mobile<br/>Cover Plus 12M',
    'ATDREIAUSADLDEW1201': 'Mobile<br/>Cover 12M',
    'ATDREIAUSADLDEW101': 'Discounted Drei <br/> Mobile Cover',
    'ATDREIAUSADLDEWTHEFT101': 'Discounted Drei <br/> Mobile Cover Plus',
    'ATDREIAUSADLDEW24': 'Mobile Cover<br/>with ACS<br/>24M',
    'ATDREIAUSADLDEWTHEFT24': 'Mobile Cover Plus<br/>with ACS<br/>24M'
    //'AT_DREI_SMART_COVER_PLUS_1M': 'Mobile<br/>Cover Plus',
    //'AT_DREI_SMART_COVER_1M': 'Mobile<br/>Cover',
    //'AT_DREI_ADLD_EW_1M': 'Mobile<br/>Cover Display'
}