import LOCALES from '../locales';

export default {
    [LOCALES.GERMEN]: {
        'home.banner.heading': 'Sicherheit für Ihr Gerät',
        'home.banner.btnText': 'Wählen Sie Ihren Schutz',
        'home.protection.heading': 'Keine Sorge um Ihr Gerät!',
        'home.protection.instantCover.heading': 'Sofortiger Schutz',
        'home.protection.instantCover.paragraph': 'Sie sind ab dem Kaufzeitpunkt geschützt. Bis zu zwei Schadenfälle innerhalb von 12 Monaten sind versichert.' ,
        'home.protection.worldWideCover.heading': 'Weltweiter Schutz',
        'home.protection.worldWideCover.paragraph': 'Der Versicherungsschutz für Ihr Gerät besteht weltweit. Außerhalb Europa, Norwegen und Schweiz bestehen Einschränkungen bzgl. der Lieferbarkeit von Ersatzgeräten. Bitte beachten Sie die Versicherungsbedingungen.',
        'home.protection.deviceMakeModel.heading': 'Jede Marke, jedes Modell bis 90 Tage alt',
        'home.protection.deviceMakeModel.paragraph': 'Jedes neu erworbene Gerät von Drei kann innerhalb von 90 Tagen ab Kaufdatum versichert werden sofern es sich in unbeschädigtem Zustand befindet.',
        'home.protection.deviceTrust.heading': 'Qualität der Sie vertrauen können',
        'home.protection.deviceTrust.paragraph': 'In Zusammenarbeit mit dem führenden Versicherer AIG.',
        'home.products.popular' : "Umfassender Schutz",
        "home.products.cta.learnmore":"Mehr erfahren",
        'home.products.yourDevice': 'Ihr Gerät',
        'home.products.heading': 'Wählen Sie Ihren Schutz ',
        'home.footer.tnc': "Nutzungsbedingungen",
        'home.footer.disclaimer': "Impressum ",
        'home.footer.copyrights': "© Bolttech Digital Insurance Agency (EU) GmbH.",
        'home.footer.allRightsReserved': "Alle Rechte vorbehalten ",
        'home.footer.thePower': "The power of <bold>connection</bold>.",
        'ordersummary.insuredDevice': 'Versichertes Gerät',
        'ordersummary.insuredDevice.brand': 'Marke',
        'ordersummary.insuredDevice.model': 'Modell',
        'ordersummary.insuredDevice.dateOfPurchase': 'Kaufdatum',
        'ordersummary.insuredDevice.mobileNumber': 'Mobilnummer',
        'ordersummary.insuredDevice.imei': 'IMEI',
        'ordersummary.policyInfo': 'Versicherungsnehmer',
        'ordersummary.policyInfo.name': 'Vorname',
        'ordersummary.policyInfo.surname': 'Nachname',
        'ordersummary.policyInfo.companyName': 'Firma',
        'ordersummary.policyInfo.email': 'Email',
        'ordersummary.policyInfo.sContactNo': 'alternative Telefonnummer',
        'ordersummary.policyInfo.email': 'Email',
        'ordersummary.policyInfo.street': 'Straße 1',
        'ordersummary.policyInfo.postalCode': 'Postleitzahl',
        'ordersummary.policyInfo.city': 'Stadt ',
        'ordersummary.paymentInfo': 'Zahlungsinformation',
        'ordersummary.paymentInfo.method': 'Zahlungsart',
        'ordersummary.paymentInfo.methodValue': 'Drei Rechnung',
        'ordersummary.paymentInfo.ocurrence': 'Fälligkeit',
        'ordersummary.paymentInfo.ocurrenceValue': 'monatlich im Voraus',
        'ordersummary.btn.submit': 'Bestellung absenden',
        'deviceinfo.heading': "Versichertes Gerät",
        'form.cta.continue': "Weiter ",
        "form.optional": "optional",
        "progress.item1": "Gerätedetails",
        "progress.item2": "Versicherungsnehmer",
        "progress.item3": "Zusammenfassung ",
        "deviceinfo.form.brand": 'Marke',
        "deviceinfo.form.model": 'Modell',
        "deviceinfo.form.dateofPurchase": 'Kaufdatum',
        "deviceinfo.form.customerPhoneNo": 'Mobilnummer',
        "deviceinfo.form.imei": "IMEI",
        "plan.limit.header": "Max. Höhe / Schadenfall",
        "plan.collabration.header": "In Zusammenarbeit mit",
        "plan.contract.header": "Vertragslaufzeit",
        "plan.payment.header": "Zahlung",
        "plan.total.header": "Gesamt",
        "contactinfo.form1.header": "Kontaktdaten",
        "contactinfo.form2.header": "Adresse",
        "contact.form.customerFirstName": "Vorname",
        "contact.form.customerLastName": "Nachname",
        "contact.form.companyName": "Firma",
        "contact.form.customerEmail": "Email",
        "contact.form.customerPhoneNo": "Mobilnummer",
        "contact.form.customerSecondaryPhoneNo": "alternative Telefonnummer",
        "contact.form.street1": "Straße und Hausnummer",
        "contact.form.street2": "Adresszusatz",
        "contact.form.zipCode": "Postleitzahl",
        "contact.form.city": "Stadt",
        "contact.form.customerFirstName.errorText": "Bitte geben Sie Ihren Vornamen ein",
        "contact.form.customerLastName.errorText": "Bitte geben Sie Ihren Nachnamen ein",
        "contact.form.customerEmail.errorText": "Bitte geben Sie eine gültige eMail Adresse an",
        "contact.form.street1.errorText": "Bitte geben Sie Ihre Adresse ein",
        "contact.form.zipCode.errorText": "Bitte geben Sie eine gültige 4 stellige Postleitzahl an",
        "contact.form.city.errorText": "Bitte geben Sie Ihre Stadt an",
        "contact.form.userConsent.errorText": "Sie müssen diese Einwilligung lesen und akzeptieren",
        "contact.form.customerSecondaryPhoneNo.errorText": "Bitte geben Sie eine gültige Telefonnummer ein",
        "contact.form.userConsentText": "Ich bestätige, dass mir die <a href={pdfLink} download>AVBs und die Datenschutzerklärung</a> zur Verfügung gestellt wurden.",
        "payment.success.message1":"Ihr Gerät ist geschützt.",
        "payment.success.deferred.message1":"Achtung – verzögerte Gerätezustellung! Es besteht noch kein Schutz!",
        "payment.success.message2":"Sie werden in Kürze ein eMail erhalten.",
        "payment.failed.message1":"Leider ist ein Fehler aufgetreten.",
        "payment.failed.deferred.message1":"Achtung – verzögerte Gerätezustellung! Es besteht noch kein Schutz!",
        "payment.failed.ageRestriction":"Die Bestellung kann aufgrund einer Altersbeschränkung nicht fortgesetzt werden. Bitte überprüfen Sie Ihre Kontoeinstellungen im Bereich “Partnerdienste” in der Drei Kundenzone oder setzen Sie sich mit dem Drei Kundenservice in Verbindung.",
        "payment.failed.insufficientCredit":"Die Bestellung kann nicht fortgesetzt werden, weil entweder nicht genug Guthaben vorhanden ist bzw. das Rechnungsvolumen zu hoch ist.{br}{br}Bitte informieren Sie den Drei IT Helpdesk wenn diese Fehlermeldung für Sie nicht nachvollziehbar erscheint.",
        "payment.failed.transactionLimit":"Diese Bestellung kann nicht fortgesetzt werden, weil ein aktives Transaktionslimits den Abschluss verhindert. Bitte überprüfen Sie Ihre Kontoeinstellungen in der Drei Kundenzone unter “Startseite > Mehrwertdienste > Partnerdienste administrieren” oder wenden Sie sich an den Drei Support.",
        "payment.failed.somethingWentWrong":"Diese Bestellung kann nicht abgeschlossen werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Drei Support.",
        'deviceinfo.verify.imei.error' : "Das Gerät kann nicht bei Apple Care Service registriert werden. Weitere Informationen erhalten Sie von Apple Care."

    }
}


export const PRODUCT_DESCRIPTION = {
    //'AT_DREI_SMART_COVER_PLUS_1M': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    //'AT_DREI_SMART_COVER_1M': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    //'AT_DREI_ADLD_EW_1M': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.'

    'ATDREIAUSADLDEWTHEFT12': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEW12': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEWTHEFT1201': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEW1201': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEWTHEFT101': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEW101': 'Dieses Produkt ist für alle geeignet, die einen umfangreichen Schadens- und Diebstahlschutz für ihr mobiles Gerät benötigen. Dazu gehören Deckung bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie, bei versehentlichen Schäden wie ein zertrümmerter Bildschirm sowie bei Diebstahl (polizeiliche Diebstahlanzeige vorausgesetzt). Diese Abdeckung gibt Ihnen  die Möglichkeit, zwischen Ersatz oder Reparatur für Ihr Gerät zu wählen. Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen.',
    'ATDREIAUSADLDEWTHEFT24':'Dieses Produkt eignet sich für alle, die umfassenden Schadensschutz für ihr Mobilgerät benötigen. Dies umfasst den Schutz bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie sowie bei Unfallschäden wie einem zerbrochenen Bildschirm. Mit diesem Schutz haben Sie die Wahl zwischen Ersatz oder Reparatur Ihres Geräts. Weitere Informationen, einschließlich Selbstbeteiligungen und Ausschlüssen, finden Sie in der',
    'ATDREIAUSADLDEW24':'Dieses Produkt eignet sich für alle, die umfassenden Schadensschutz für ihr Mobilgerät benötigen. Dies umfasst den Schutz bei mechanischen oder elektrischen Ausfällen nach Ablauf der Herstellergarantie sowie bei Unfallschäden wie einem zerbrochenen Bildschirm. Mit diesem Schutz haben Sie die Wahl zwischen Ersatz oder Reparatur Ihres Geräts. Weitere Informationen, einschließlich Selbstbeteiligungen und Ausschlüssen, finden Sie in der'
    

}

export const FEATURE = {
    EXTEND_PROTECTION: 'Schützen Sie Ihr Gerät bei versehentlichen Beschädigungen sowie nach Ablauf der Herstellergarantie auch bei elektronischen und mechanischen Defekten',
    CHOOSE_BETWEEN: 'Wählen Sie zwischen Reparatur und Austausch Ihres Geräts',
    REPLACEMENT: 'Ersatz Ihres Geräts bei Raub oder Diebstahl',
    PROTECT: 'Schützen Sie nur den Bildschirm Ihres Geräts bei versehentlichen Beschädigungen sowie nach Ablauf der Herstellergarantie auch bei elektronischen und mechanischen Defekten',
    FREE_PROTECTION_FIRST_2_MONTHS: 'Zwei Monate kostenlos bei monatlicher Verlängerung nach 12 Monaten fixer Absicherung',
    FREE_PROTECTION_FIRST_MONTHS: 'Erster Monat kostenlos bei monatlicher Verlängerung der Versicherung',
    DISCOUNTED_PREMIUM_FF: '-50% auf die reguläre Prämie bei monatlicher Verlängerung der Versicherung und keine Bindung',
    THEFT_PROTECTION_FF: 'Darüberhinaus ist auch Diebstahlschutz inkludiert',
    ACS_FREE_PROTECTION_FIRST_2_MONTHS: 'Zwei Monate kostenlos bei monatlicher Verängerung nach 24 Monaten Bindung.',
    ACS_EXTEND_PROTECTION: 'Schützen Sie Ihr Gerät bei Unfallschäden sowie bei elektronischen und mechanischen Defekten nach Ablauf der Herstellergarantie.',
    ACS_CHOOSE_BETWEEN: 'Wählen Sie zwischen Reparatur und Ersatz Ihres Geräts.',
    ACS_REPLACEMENT: 'Im Falle eines Diebstahls wird Ihr Gerät ersetzt'
}

export const BREAKDOWN = {
    HEADING: 'Mechanische & Elektrische Defekte',
    WE_WILL_COVER: 'Sie sind versichert, wenn Ihr Gerät nach Ablauf der Herstellergarantie ausfällt. Zum Beispiel bei defekten Tasten, fehlerhafter Kopfhörerbuchse oder anderen mechanischen oder elektrischen Pannen.',
    WE_WILL_COVER_TERMS: 'Sie sind versichert, wenn Ihr Display nach Ablauf der Herstellergarantie  ausfällt.',
    PLEASE_SEE: 'Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen Ihres Versicherungsschutzes'
}

export const ACCIDENTAL_DAMAGE = {
    HEADING: 'Zufällige Schäden',
    PARA_1: 'Wenn Ihr Gerät durch einen Unfall beschädigt wird, z. B. durch Sturz, oder wenn Ihr Gerät versehentlich mit Flüssigkeit in Berührung kommt, reparieren oder ersetzen wir es.',
    PARA_2: 'Weitere Informationen einschließlich Selbstbehalten und Ausschlüssen finden Sie in den Allgemeinen Geschäftsbedingungen Ihres Versicherungsschutzes'
}

export const REPLACE_REPAIR = {
    HEADING: 'Austausch oder Reparatur',
    PARA_1: 'Falls Ihr Handy beschädigt ist, haben Sie die Möglichkeit, zwischen Ersatzgerät oder Reparatur zu wählen. Bitte beachten Sie die Versicherungsbedingungen.',
}

export const ACS_BREAKDOWN = {
    HEADING: 'Mechanische und elektrische Defekte',
    WE_WILL_COVER: 'Sie sind versichert, wenn Ihr Gerät nach Ablauf der Herstellergarantie ausfällt. Zum Beispiel bei defekten Tasten, defektem Kopfhöreranschluss, Batterieleistung unter 80% oder anderen mechanischen oder elektrischen Defekten.',
    WE_WILL_COVER_TERMS: 'Sie sind versichert, wenn Ihr Gerät nach Ablauf der Herstellergarantie ausfällt.',
    PLEASE_SEE: 'Weitere Informationen, einschließlich der Selbstbeteiligungen und Ausschlüsse, finden Sie in den Versicherungsbedingungen für Ihren Versicherungsschutz'
}

export const ACS_ACCIDENTAL_DAMAGE = {
    HEADING: 'Unbeabsichtigte Beschädigung',
    PARA_1: 'Wenn Ihr Gerät durch einen Unfall beschädigt wird, z. B. durch einen Sturz, oder wenn Ihr Gerät versehentlich mit Flüssigkeit in Berührung kommt, reparieren oder ersetzen wir es.',
    PARA_2: 'Weitere Informationen, einschließlich der Selbstbeteiligungen und Ausschlüsse, finden Sie in den Versicherungsbedingungen für Ihren Versicherungsschutz'
}

export const ACS_REPLACE_REPAIR = {
    HEADING: 'Ersatz oder Reparatur',
    PARA_1: 'Wenn Ihr Telefon beschädigt ist, haben Sie die Möglichkeit, zwischen Ersatz und Reparatur zu wählen.  Sie können Ihr Gerät in jedem lokalen Apple Store oder bei einem Apple Service Provider weltweit reparieren lassen.  Bitte beachten Sie die Versicherungsbedingungen.',
}


export const THEFT = {
    HEADING: 'Diebstahl & Raub',
    PARA_1: 'Wenn Sie das Pech haben, dass Ihr Gerät im In- oder Ausland gestohlen wird, ersetzen wir es. Bitte beachten Sie die Versicherungsbedingungen.',
}

export const REPAIR = {
    HEADING: 'Zufällige Schäden',
    PARA_1: 'Wenn das Display Ihres Geräts durch einen Unfall beschädigt wird, z. B. durch Sturz, oder wenn Ihr Gerät versehentlich mit Flüssigkeit in Berührung kommt, reparieren wir es.',
}

export const PRODUCT_NAME = {
    'ATDREIAUSADLDEWTHEFT12': 'Mobile<br/>Cover Plus',
    'ATDREIAUSADLDEW12': 'Mobile<br/>Cover',
    'ATDREIAUSADLDEWTHEFT1201': 'Mobile<br/>Cover Plus 12M',
    'ATDREIAUSADLDEW1201': 'Mobile<br/>Cover 12M',
    'ATDREIAUSADLDEW101': 'Discounted Drei <br/> Mobile Cover',
    'ATDREIAUSADLDEWTHEFT101': 'Discounted Drei <br/> Mobile Cover Plus',
    'ATDREIAUSADLDEW24': 'Mobile Cover<br/>with ACS<br/>24M',
    'ATDREIAUSADLDEWTHEFT24': 'Mobile Cover Plus<br/>with ACS<br/>24M'
   // 'AT_DREI_SMART_COVER_PLUS_1M': 'Mobile<br/>Cover Plus',
   // 'AT_DREI_SMART_COVER_1M': 'Mobile<br/>Cover',
   // 'AT_DREI_ADLD_EW_1M': 'Mobile<br/>Cover Display'

}